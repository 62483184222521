<template>
    <div :id="_comId" :class="`no-select ${__class}`">
        <div class="header" v-if="format != 'time'&&format != 'month'">
            <table>
                <tr>
                    <td>
                        <a href="javascript:void(0)" class="prev-year" @click="prevYear">
                            <i class="icon fa fa-angle-double-left"></i>
                        </a>
                    </td>
                    <td>
                        <a href="javascript:void(0)" class="prev-month" @click="prevMonth">
                            <i class="icon fa fa-angle-left"></i>
                        </a>
                    </td>
                    <td>
                        <span class="current" @click="choose='month-panel'"><b>{{year}}年</b>&nbsp;<i>{{monthsShort[month]}}</i></span>
                    </td>
                    <td>
                        <a href="javascript:void(0)" class="next-month" @click="nextMonth">
                            <i class="icon fa fa-angle-right"></i>
                        </a>
                    </td>
                    <td>
                        <a href="javascript:void(0)" class="next-year" @click="nextYear">
                            <i class="icon fa fa-angle-double-right"></i>
                        </a>
                    </td>
                </tr>
            </table>
        </div>
        <transition
            :leave-active-class="`animated fadeOutLeft`"
            :enter-active-class="`animated fadeInLeft`">
            <div class="body" v-show="(format=='datetime'||format=='date')&&choose=='date-panel'">
                <div class="date-panel" ref="date-panel">
                    <div class="weeks">
                        <span :key="index" v-for="(week,index) in weeks">{{week}}</span>
                    </div>
                    <div class="dates">
                        <div class="row" :rowIndex="row.index" :key="index" v-for="(row,index) in dateRows">
                            <span @click="setDay(`${item.text}`)" :class="`column ${(item.text == getNow('day') && item.month == getNow('month') && item.year == getNow('year'))?'today':item.type} `" :column="item.column" :rowIndex="item.row" :key="item_index" v-for="(item,item_index) in row">{{item.text}}</span>
                        </div>
                    </div>
                </div>
                <div class="time-picker" v-if="format=='datetime'">
                    <span><i class="icon fa fa-clock-o"></i></span>
                    <span><input class="h" type="text" placeholder="H" v-model="hours"></span>
                    <span><b>:</b></span>
                    <span><input class="m" type="text" placeholder="M" v-model="minutes"></span>
                </div>
                <div class="bottom-buttons">
                    <a class="button button-square button-small" @click="cancel"><span>取消</span></a>
                    <a class="button button-square button-small" @click="select"><span>选择</span></a>
                </div>
            </div>
        </transition>
        <transition
            :leave-active-class="`animated fadeOut`"
            :enter-active-class="`animated fadeIn`">
            <div class="body" v-show="(format=='datetime'||format=='date')&&choose=='month-panel'">
                <div class="month-panel">
                    <div class="select-year" ref="select-year">
                        <div @click="selectYearMonth" :value='row.value' :class="row.type" :key="r_index" v-for="(row,r_index) in yearRows">{{row.year}}</div>
                    </div>
                    <div class="select-month" ref="select-month">
                        <div @click="selectYearMonth" :value='row.value' :class="row.type" :key="r_index" v-for="(row,r_index) in monthRows">{{row.month}}</div>
                    </div>

                </div>

                <div class="bottom-buttons">
                    <a class="button button-square button-small" @click="setYearMonth"><span>选择</span></a>
                </div>
            </div>
        </transition>

        <transition
            :leave-active-class="`animated fadeOut`"
            :enter-active-class="`animated fadeIn`">
            <div class="body" v-show="format=='time'">
                <div class="time-panel">
                    <div class="select-hours" ref="select-hours">
                        <div @click="selectHour" :value='row.value' :class="row.type" :key="r_index" v-for="(row,r_index) in hourRows">{{row.hour}}</div>
                    </div>
                    <div class="select-minutes" ref="select-minutes">
                        <div @click="selectTime" :value='row.value' :class="row.type" :key="r_index" v-for="(row,r_index) in minuteRows">{{row.minute}}</div>
                    </div>
                    <div class="right-buttons">
                        <a class="button button-square button-small" @click="select"><span>选择</span></a>
                        <a class="button button-square button-small" @click="cancel"><span>取消</span></a>
                    </div>
                </div>
            </div>
        </transition>

        <transition
            :leave-active-class="`animated fadeOut`"
            :enter-active-class="`animated fadeIn`">
            <div class="body" v-show="format=='month'">
                <div class="single-month-panel">
                    <div class="select-single-month" ref="select-single-month">
                        <div @click="selectMonth" :value='row.value' :class="row.type" :key="r_index" v-for="(row,r_index) in monthRows">{{row.month}}</div>
                    </div>

                    <div class="right-buttons">
                        <a class="button button-square button-small" @click="select"><span>选择</span></a>
                        <a class="button button-square button-small" @click="selectNowMonth"><span>now</span></a>
                        <a class="button button-square button-small" @click="cancel"><span>取消</span></a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Common from '../../utils/common'
import Scrollbar from 'smooth-scrollbar'

const emit = {
    SELECTED: 'onSelected',
    UNDO: 'onUndo'
};
    /*
     * 项目   vue
     * 作者   loufei
     * 时间   2018/2/22
     */
export default {
    data() {
        return {
            weeks: ['日', '一', '二', '三', '四', '五', '六'],
            monthsShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            'pickers': ['未来7天', '未来30天', '最近7天', '最近30天'],
            'placeholder': {
                'date': '请选择日期',
                'dateRange': '请选择日期范围'
            },
            readonly: false,
            disabled: false,
            align: 'left',
            startDate: null,
            endDate: null,
            rangeSeparator: '-',
            weekStart: 0,
            defaultValue: '',
            zIndex: 2008,
            onChange: null,
            onShow: null,
            onHide: null,
            choose: 'date-panel',
            hourBar: null,
            minuteBar: null,
            monthBar: null,
            now: new Date(),
            yearScrollbar: null,
            monthScrollbar: null

        }
    },
    props: {
        '_value': null,
        '_format': {
            type: String,
            default: 'datetime'
        },
        '_open': Boolean
    },
    computed: {
        date() {
            if (this.value && this.format === 'time') {
                return Common.DATE.parse(Common.DATE.format(this.now, Common.DATE_FORMAT['date'][0]) + ' ' + this.value)
            } else if (this.value && this.format === 'month') {
                return Common.DATE.setMonth(this.now, Number(this.value) - 1)
            } else if (this.value && Common.DATE_FORMAT[this.format][1].test(this.value)) {
                return Common.DATE.parse(this.value)
            } else {
                return new Date();
            }
        },
        year() {
            return Common.DATE.getYear(this.date)
        },
        month() {
            return Common.DATE.getMonth(this.date)
        },
        day() {
            return Common.DATE.getDate(this.date)
        },
        hours: {
            get: function () {
                return Common.DATE.getHours(this.date);
            },
            set: function (value) {
                this.setDateTime(Common.DATE.setHours(this.date, value));
            }
        },
        minutes: {
            get: function () {
                return Common.DATE.getMinutes(this.date);
            },
            set: function (value) {
                this.setDateTime(Common.DATE.setMinutes(this.date, value));
            }
        },
        dateRows() {
            let rows = [[], [], [], [], [], []];
            let year = this.date.getFullYear();
            let month = this.date.getMonth();

            let firstDayOfMonth = Common.DATE.startOfMonth(this.date);
            let firstDayOfWeek = Common.DATE.getDay(firstDayOfMonth);
            let dateCountOfMonth = Common.DATE.getDaysInMonth(firstDayOfMonth);
            let dateCountOfLastMonth = Common.DATE.getDaysInMonth(year, (month === 0 ? 11 : month - 1));

            let offset = -1 * this.weekStart;

            let count = 1;
            let firstDayPosition;

            for (let i = 0; i < 6; i++) {
                let row = rows[i];
                for (let j = 0; j < 7; j++) {
                    let cell = row[j];
                    if (!cell) {
                        cell = { row: i, column: j, type: 'normal', year: year, month: month }; // init cell
                    }
                    cell.type = 'normal';
                    cell.year = year;
                    cell.month = month;

                    if (year === this.year && month === this.month && count === this.date.getDate()) {
                        cell.type = 'select';
                    }

                    if (i === 0 || i === 1) {
                        if (j + i * 7 >= (firstDayOfWeek + offset)) {
                            cell.text = count++;
                            if (count === 2) {
                                firstDayPosition = i * 7 + j;
                            }
                            cell.year = year;
                            cell.month = month;
                        } else {
                            cell.text = dateCountOfLastMonth - (firstDayOfWeek + offset - j % 7) + 1 + i * 7;
                            cell.type = 'prev-month';
                            cell.year = month === 0 ? year - 1 : year;
                            cell.month = month === 0 ? 11 : month - 1;
                        }
                    } else {
                        if (count <= dateCountOfMonth) {
                            cell.text = count++;
                            if (count === 2) {
                                firstDayPosition = i * 7 + j;
                            }
                            cell.year = year;
                            cell.month = month;
                        } else {
                            cell.text = count++ - dateCountOfMonth;
                            cell.type = 'next-month';
                            cell.year = month === 11 ? year + 1 : year;
                            cell.month = month === 11 ? 0 : month + 1;
                        }
                    }
                    console.log('firstDayPosition:', firstDayPosition)
                    row[j] = cell;
                }
            }
            return rows;
        },
        monthRows() {
            let monthsRows = [];
            let month = this.date.getMonth();
            let monthsArray = this.monthsShort;
            for (let i = 0; i < monthsArray.length; i++) {
                monthsRows.push({
                    value: i,
                    month: i + 1,
                    type: month === i ? 'select' : 'normal'
                });
            }
            return monthsRows;
        },
        yearRows() {
            let year = this.date.getFullYear()
            let yearRows = [];

            for (let y = 1900; y < 2050; y++) {
                yearRows.push({
                    year: y,
                    value: y,
                    type: year === y ? 'select' : 'normal'
                });
            }

            return yearRows;
        },
        hourRows() {
            let hourRows = [];

            for (let h = 0; h <= 23; h++) {
                hourRows.push({
                    hour: h,
                    value: h,
                    type: this.hours === h ? 'select' : 'normal'
                });
            }

            return hourRows;
        },
        minuteRows() {
            let minuteRows = [];
            for (let m = 0; m <= 59; m++) {
                minuteRows.push({
                    minute: m,
                    value: m,
                    type: this.minutes === m ? 'select' : 'normal'
                });
            }
            return minuteRows;
        }
    },
    watch: {
        open(value) {
            if (value) {
                if (this.format === 'time') { this.initTimeScroll(); }
                if (this.format === 'month') { this.initMonthScroll() }
            }
        },
        value() {
            this.$nextTick(() => {
                if (this.choose === 'month-panel') {
                    let year = this.$refs['select-year'];
                    let month = this.$refs['select-month'];
                    if (this.yearScrollbar) {
                        this.yearScrollbar.scrollTo(0, $($(year).find('.select')[0]).index() * 53 - 60, 100);
                    }
                    if (this.monthScrollbar) {
                        this.monthScrollbar.scrollTo(0, $($(month).find('.select')[0]).index() * 53 - 60, 100);
                    }
                }
            })
        }
    },
    methods: {
        getNow(which) {
            if (which === 'day') {
                return this.now.getDate()
            } else if (which === 'month') {
                return this.now.getMonth()
            } else if (which === 'year') {
                return this.now.getFullYear()
            }
        },
        selectYearMonth(event) {
            let el = event.target;
            let parent = el.parentNode;
            el.parentNode.querySelector('.select').className = 'normal'
            el.className = 'select';
            let year = parent.querySelector('.select-year .select').getAttribute('value');
            let month = parent.querySelector('.select-month .select').getAttribute('value');
            let select = Common.DATE.setMonth(Common.DATE.setYear(this.date, year), month);
            this.setDateTime(select);
        },
        selectMonth(event) {
            let el = event.target;
            let parent = el.parentNode;
            parent.querySelector('.select').className = 'normal'
            el.className = 'select'
            let month = parent.querySelector('.select-single-month .select').getAttribute('value');
            let select = Common.DATE.setMonth(this.date, month);
            this.setDateTime(select);
        },
        setYearMonth(event) {
            let el = event.target.parentNode.parentNode.parentNode;
            let year = el.querySelector('.select-year .select').getAttribute('value');
            let month = el.querySelector('.select-month .select').getAttribute('value');
            let select = Common.DATE.setMonth(Common.DATE.setYear(this.date, year), month);
            this.setDateTime(select);
            this.choose = 'date-panel'
        },
        setDay(day) {
            this.setDateTime(Common.DATE.setDate(this.date, day))
        },
        select() {
            this.setDateTime(Common.DATE.setSeconds(this.date, 0))
            this.$emit(emit.SELECTED, {
                date: this.date
            })
        },
        cancel() {
            this.$emit(emit.UNDO);
        },
        selectNowTime() {
            this.initTimeScroll();
            const hours = this.$refs['select-hours'];
            const h_target = hours.querySelector('.select');
            this.hourBar.scrollTo(0, $(h_target).position().top, 600);
            this.hourBar.update();
            const minutes = this.$refs['select-minutes'];
            const m_target = minutes.querySelector('.select');
            this.minuteBar.scrollTo(0, $(m_target).position().top, 600);
            this.minuteBar.update();
        },
        initTimeScroll() {
            if (this.hourBar) this.hourBar.destroy();
            if (this.minuteBar) this.minuteBar.destroy();
            this.hourBar = Scrollbar.init(this.$refs['select-hours']);
            this.minuteBar = Scrollbar.init(this.$refs['select-minutes']);
        },
        selectNowMonth() {
            this.initMonthScroll();
            const month = this.$refs['select-single-month'];
            const target = month.querySelector('.select');
            this.monthBar.scrollTo(0, $(target).position().top, 600);
        },
        initMonthScroll() {
            if (this.monthBar) this.monthBar.destroy();
            this.monthBar = Scrollbar.init(this.$refs['select-single-month']);
        },
        selectHour(event) {
            let el = event.target;
            let parent = el.parentNode;
            parent.querySelector('.select-hours .select').className = 'normal'
            el.className = 'select';
            let hourDom = parent.querySelector('.select-hours .select');
            let hour = hourDom ? hourDom.getAttribute('value') : 1;
            this.setDateTime(Common.DATE.setSeconds(Common.DATE.setHours(this.date, hour), 0));
        },
        selectTime(event) {
            let el = event.target;
            let parent = el.parentNode;
            parent.querySelector('.select-minutes .select').className = 'normal'
            el.className = 'select';
            let minuteDom = parent.querySelector('.select-minutes .select');
            let minute = minuteDom ? minuteDom.getAttribute('value') : 0;
            this.setDateTime(Common.DATE.setSeconds(Common.DATE.setMinutes(this.date, minute), 0));
        },
        setDateTime(date) {
            this.value = Common.DATE.format(date, Common.DATE_FORMAT[this.format][0]);
        },
        nextYear() {
            if (this.year === this.yearRows[this.yearRows.length - 1].value) return;
            this.setDateTime(Common.DATE.addYears(this.date, 1))
        },
        prevYear() {
            if (this.year === this.yearRows[0].value) return;
            this.setDateTime(Common.DATE.subYears(this.date, 1))
        },
        nextMonth() {
            if (this.month === 11) return;
            this.setDateTime(Common.DATE.addMonths(this.date, 1))
        },
        prevMonth() {
            this.setDateTime(Common.DATE.subMonths(this.date, 1))
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {

    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {

    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.$watch('choose', () => {
            if (this.choose === 'month-panel') {
                let year = this.$refs['select-year'];
                this.yearScrollbar = Scrollbar.init(year);
                this.yearScrollbar.scrollTo(0, $($(year).find('.select')[0]).index() * 53 - 60, 100);
                let month = this.$refs['select-month'];
                this.monthScrollbar = Scrollbar.init(month);
                this.monthScrollbar.scrollTo(0, $($(month).find('.select')[0]).index() * 53 - 60, 100);
            }
        })
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {

    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {

    },
    /* keep-alive 组件激活时调用。 */
    activated() {

    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
