/**
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2018/12/29
 */
/**
 * 组框架
 * @module FrameWork
 */
import Vue from "vue";
import Rest from "./utils/rest";
import Common from "./utils/common";
import Gl from "./utils/gl";
import Socket from "./utils/socket";
import Options, { OPT } from "./utils/options";
import validators from "./utils/validators";
import Task from "./utils/task";
import axios from "axios";
import qs from "qs";
import JgpDragResize from "./components/drag/JgpDragResize.vue";

/* 插件 */
import VeeValidate, { Validator } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN";

import VTooltip from "v-tooltip";

// 公共组件
import Base from "./components/Base.vue";

Vue.component("jgp-drag-resize", JgpDragResize);

// 全局配置
Vue.config.productionTip = false;
Vue.use(VeeValidate, {
    locale: "zh_CN",
    dictionary: {
        zh_CN
    }
});
for (let validator in validators) {
    Validator.extend(validator, validators[validator]);
}
Vue.use(VTooltip);

Vue.mixin(Base);
/**
 * 主框架类
 * @class ./FrameWork
 */
export default class FrameWork {
    static mainViewData = {};
    static MainMask;
    static MainView;
    /**
     * @see The <a href="./utils/Common">llying</a >.
     */
    static Options = Options;
    /**
     * opt {}
     * @see my/shirt.wash
     * @property 啊
     */
    static opt = Options.option;
    static global = Options.global;
    static config = Options.USER_CONFIG;
    static Utils = Common;
    static Rest = Rest;
    static Gl = Gl;
    static Date = Common.DATE;
    static Task = Task;
    static Socket = Socket;
    static axios = axios;
    static qs = qs;
    static layoutBorder = comId => Options.com(OPT.LAYOUT_BORDER.type, comId);
    static div = comId => Options.com(OPT.DIV.type, comId);
    static form = comId => Options.com(OPT.FORM.type, comId);
    static formGroup = comId => Options.com(OPT.FORM_GROUP.type, comId);
    static query = comId => Options.com(OPT.QUERY.type, comId);
    static panel = comId => Options.com(OPT.PANEL.type, comId);
    static btn = comId => Options.com(OPT.BTN.type, comId);
    static tree = comId => Options.com(OPT.TREE.type, comId);
    static tree2 = comId => Options.com(OPT.TREE2.type, comId);
    static tree3 = comId => Options.com(OPT.TREE3.type, comId);
    static list = comId => Options.com(OPT.LIST.type, comId);
    static grid = comId => Options.com(OPT.GRID.type, comId);
    static gridCol = comId => Options.com(OPT.GRID_COL.type, comId);
    static slider = comId => Options.com(OPT.SLIDER.type, comId);
    static tabPanel = comId => Options.com(OPT.TAB_PANEL.type, comId);
    static tab = comId => Options.com(OPT.TAB.type, comId);
    static text = comId => Options.com(OPT.TEXT.type, comId);
    static area = comId => Options.com(OPT.AREAR.type, comId);
    static num = comId => Options.com(OPT.NUM.type, comId);
    static datetime = comId => Options.com(OPT.DATETIME.type, comId);
    static checkbox = comId => Options.com(OPT.CHECKBOX.type, comId);
    static radio = comId => Options.com(OPT.RADIO.type, comId);
    static drop = comId => Options.com(OPT.DROP.type, comId);
    static hidden = comId => Options.com(OPT.HIDDEN.type, comId);
    static uploader = comId => Options.com(OPT.UPLOAD.type, comId);
    static img = comId => Options.com(OPT.IMG.type, comId);
    static dialog = comId => Options.getDialog(comId);
    static richEditor = comId => Options.com(OPT.RICH_EDITOR.type, comId);
    static advSite = comId => Options.com(OPT.ADV_SITE.type, comId);
    static advTable = comId => Options.com(OPT.ADV_TABLE.type, comId);
    static advRoleTree = comId => Options.com(OPT.ADV_SITE.type, comId);
    static procOperator = comId => Options.com(OPT.PROC_OPERATOR.type, comId);
    static glPile = comId => Options.com(OPT.GL_PILE.type, comId);
    static advUserInfo = comId => Options.com(OPT.ADV_USER_INFO.type, comId);
    /**
     * @name $close
     * @function
     * @param args {Any}
     * @desc 关闭顶层弹窗
     */
    static $close = Common.closeDialog;

    static $closeAll = Common.closeAllDialog;
    /**
     * @name $closeSelf
     * @function
     * @param args {Any}
     * @desc 关闭本窗口顶层弹窗
     */
    static $closeSelf = Common.closeLocalDialog;

    /**
     * @name $dialog
     * @function
     * @param options {Object} {title,url,height,width,callback}
     * @desc 弹窗
     */
    static $dialog = Common.openDialog;
    /**
     * @name $alert
     * @function
     * @param msg {String}
     * @desc 提示
     */
    static $alert = Common.alert;
    /**
     * @name $warn
     * @function
     * @param msg {String}
     * @desc 警告
     */
    static $warn = Common.warn;
    /**
     * @name $error
     * @function
     * @param msg {String}
     * @desc 错误
     */
    static $error = Common.error;
    /**
     * 确认框
     * @param msg 信息内容
     * @param okFn 确认回调
     * @param cancelFn 取消回调
     */
    static $confirm = Common.confirm;
    /**
     * @name $openAlert
     * @function
     * @param config
     * @example JGP.$openAlert({
            title: '警告！',           //标题
            type: 'warn',             //warn error info 类型
            autoHideTime: 1,          //自动隐藏时间 单位秒 如果为-1则不自动隐藏
            content: '内容'            //内容
        })
     */
    static $openAlert = Common.openAlert;
    static $album = Common.openAlbum;
    /**
     * @name $loading
     * @function
     * @param show {Boolean} true 显示 false 不显示
     * @param isTop {Boolean} loading效果是在顶层窗口显示 还是在当前窗口显示
     * @desc 错误
     */
    static $loading = Common.loading;
    /**
     * POST请求
     * @name $post
     * @function
     * @param url 地址
     * @param data 参数
     * @param callback 成功回调
     * @param errorCallback 失败回调
     * @param autoMask 自动显示隐藏遮罩
     */
    static $post = Common.post;

    /**
     * 查询参数以JSON形式发送POST请求
     * @name $post
     * @function
     * @param url 地址
     * @param data 参数
     * @param callback 成功回调
     * @param errorCallback 失败回调
     * @param autoMask 自动显示隐藏遮罩
     */
    static $postJson = Common.postJson;

    /**
     * GET请求
     * @name $get
     * @function
     * @param url 地址
     * @param data 参数
     * @param callback 成功回调
     * @param errorCallback 失败回调
     * @param autoMask 自动显示隐藏遮罩
     */
    static $get = Common.get;
    /**
     * 页面渲染后回调
     * @name $onload
     * @function
     * @param fnc
     */
    static $onload = Options.onloadFnc;
    /**
     * 上传弹窗
     * @name $upload
     * @function
     * @param config
     * @desc
     * config => objectId,objectType,allow,totalNum,totalSize,singleSize,callback,width,height
     * objectId:挂载对象id
     * objectType:挂载对象类型 com.jgp.model.AdminUser
     * objectParams:[{objectId,objectType}]
     * allow:{默认:'all'} //image,doc,video,audio,all:平台支持的所有类型,自定义后缀:'mov,jpg', any:任何类型，没有特殊情况尽量不要使用
     * disabled:{默认:'false'}
     * totalNum:{默认:10} 一次性最多上传文件个数
     * callback:弹窗关闭回调 返回参数为上传的挂载关系记录的ID集合
     * width,height:弹窗大小默认 width：500px，height：300px
     */
    static $upload = config => {
        Common.updateWin(config);
    };
    /**
     * 更新上传文件的挂载信息
     *
     * @name $updateMountInfo
     * @function
     * @param objectId 挂载对象业务id
     * @param objectType 挂载对象类型名称
     * @param fileIds 挂载主键集合
     * @param callback 回调
     */
    static $updateMountInfo = (objectId, objectType, fileIds, callback) => {
        Common.updateMountInfo(
            "/attachment/docApiController/updateMountInfo",
            objectId,
            objectType,
            fileIds,
            callback
        );
    };

    /**
     * 吊起支付页面
     *
     * @name $doPay
     * @function
     * @param configId 支付配置id
     * @param orderNo 订单号 六位随机数+long型时间
     * @param subject 主题
     * @param body  描述
     * @param money 订单金额
     * @param extParamMap 支付宝官方接口参数
     * @param jsonParams 支付异步通知的时候会携带该参数返回
     */
    static $doPay = Common.invokePagePay;
    /**
     * 批量转账
     *
     * @name $doBatchTrans
     * @function
     * @param configId 支付配置id
     * @param no 批次号
     * @param num 总笔数
     * @param money 总金额
     * @param details &#100;&#101;&#116;&#97;&#105;&#108;&#115;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#91;&#123;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#97;&#99;&#99;&#111;&#117;&#110;&#116;&#32;&#25910;&#27454;&#26041;&#36134;&#21495;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#110;&#97;&#109;&#101;&#32;&#32;&#32;&#32;&#25910;&#27454;&#26041;&#21517;&#23383;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#109;&#111;&#110;&#101;&#121;&#32;&#32;&#32;&#36716;&#36134;&#37329;&#39069;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#114;&#101;&#109;&#97;&#114;&#107;&#32;&#32;&#36716;&#36134;&#35828;&#26126;&#10;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#32;&#125;&#93;
     * @param jsonParams 支付异步通知的时候会携带该参数返回
     */
    static $doBatchTrans = Common.invokeBatchTrans;
    /**
     * 查询当前用户的支付配置
     * @name $queryPayments
     * @function
     */
    static $queryPayments = Common.queryPaymentConfigs;

    /**
     * 设置当前页面模板数据
     * @name $setData
     * @function
     */
    static $setData = (data, callback) => {
        if (!FrameWork.MainView) {
            FrameWork.mainViewData = data;
        } else {
            FrameWork.MainView.$set(
                FrameWork.MainView.$data,
                "data",
                Object.assign({}, FrameWork.MainView.$data.data, data)
            );
            FrameWork.MainView.$nextTick(() => {
                if (callback) callback();
            });
        }
    };

    /**
     * 设置当前页面模板数据
     * @name $setData
     * @function
     */
    static $putData = (key, data, callback) => {
        if (!FrameWork.MainView) {
            FrameWork.mainViewData[key] = data;
        } else {
            FrameWork.MainView.$set(
                FrameWork.MainView.$data["data"],
                key,
                data
            );
            FrameWork.MainView.$nextTick(() => {
                if (callback) callback();
            });
        }
    };

    /**
     * 获取主容器dom对象
     * @name $mainEl
     * @function
     */
    static $mainEl = () => FrameWork.MainView.$el;

    static $playAudio = (src, callback) => {
        var audio = document.createElement("AUDIO");
        audio.setAttribute("src", src);
        audio.setAttribute("id", "_audio_tip");
        audio.play();
        audio.addEventListener("ended", function() {
            if (callback) callback();
            document.getElementById("_audio_tip").remove();
        });
        document.body.appendChild(audio);
    };
}
