var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.__class,style:({ width: _vm.width, height: _vm.height }),attrs:{"id":_vm._comId}},_vm._l((_vm.reports),function(report,index){return _c('div',{key:index,class:['report', `report-${index}`]},[_c('div',{staticClass:"report__name"},[_c('span',[_vm._v(_vm._s(report.name))])]),_c('table',{attrs:{"border":"1","cellpadding":"0","cellspacing":"0"}},[_c('tr',{staticClass:"report__attrs"},[_vm._l((report.attrs),function(attr){return [_c('td',[_vm._v(_vm._s(attr.label))]),_c('td',[_vm._v(_vm._s(attr.value))])]})],2)]),_c('div',{staticClass:"report__groups"},_vm._l((report.groups),function(group,group_index){return _c('div',{key:group_index,class:`report__group-${group_index}`},[_vm._l((group.descriptions),function(description,description_index){return _c('div',{key:description_index,staticClass:"report__group-description"},[_c('p',[_vm._v(_vm._s(description))])])}),_c('div',{staticClass:"report__group_table"},[_c('table',{attrs:{"border":"1","cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',_vm._l((group.catalogs),function(catalog,catalog_index){return _c('th',{key:catalog_index},[_vm._v(" "+_vm._s(catalog.name)+" ")])}),0)]),_c('tbody',_vm._l((_vm.getMaxRow(group)),function(r){return _c('tr',{key:r},_vm._l((group.catalogs),function(catalog,catalog_index){return (
                                        _vm.checkGenerateTd(group, catalog, r)
                                    )?_c('td',{key:catalog_index,attrs:{"id":catalog_index +
                                        '-' +
                                        _vm.getRecord(group, catalog, r).id,"rowspan":_vm.getColumnRowSpan(group, catalog, r)}},[(
                                            _vm.checkGenerateTemplate(catalog)
                                        )?[_vm._l((catalog.slots),function(slotName){return _vm._t(slotName,null,{"record":_vm.getRecord(group, catalog, r)})})]:_c('span',[_vm._v(_vm._s(_vm.getColumn(group, catalog, r)))])],2):_vm._e()}),0)}),0)])])],2)}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }