<template>
    <div
        :id="_comId"
        :class="__class"
        :style="{ width: width, height: height }"
    >
        <div
            :class="['report', `report-${index}`]"
            v-for="(report, index) in reports"
            :key="index"
        >
            <div class="report__name">
                <span>{{ report.name }}</span>
            </div>
            <table border="1" cellpadding="0" cellspacing="0">
                <tr class="report__attrs">
                    <template v-for="attr in report.attrs">
                        <!--eslint-disable-next-line vue/require-v-for-key-->
                        <td>{{ attr.label }}</td>
                        <!--eslint-disable-next-line vue/require-v-for-key-->
                        <td>{{ attr.value }}</td>
                    </template>
                </tr>
            </table>
            <div class="report__groups">
                <div
                    :class="`report__group-${group_index}`"
                    v-for="(group, group_index) in report.groups"
                    :key="group_index"
                >
                    <div
                        class="report__group-description"
                        v-for="(
                            description, description_index
                        ) in group.descriptions"
                        :key="description_index"
                    >
                        <p>{{ description }}</p>
                    </div>
                    <div class="report__group_table">
                        <table border="1" cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th
                                        v-for="(
                                            catalog, catalog_index
                                        ) in group.catalogs"
                                        :key="catalog_index"
                                    >
                                        {{ catalog.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="r in getMaxRow(group)" :key="r">
                                    <td
                                        :id="
                                            catalog_index +
                                            '-' +
                                            getRecord(group, catalog, r).id
                                        "
                                        v-for="(
                                            catalog, catalog_index
                                        ) in group.catalogs"
                                        :key="catalog_index"
                                        v-if="
                                            checkGenerateTd(group, catalog, r)
                                        "
                                        :rowspan="
                                            getColumnRowSpan(group, catalog, r)
                                        "
                                    >
                                        <template
                                            v-if="
                                                checkGenerateTemplate(catalog)
                                            "
                                        >
                                            <slot
                                                v-for="slotName in catalog.slots"
                                                :name="slotName"
                                                :record="
                                                    getRecord(group, catalog, r)
                                                "
                                            ></slot
                                        ></template>
                                        <span v-else>{{
                                            getColumn(group, catalog, r)
                                        }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 *
 * 项目   jgp-front-pc
 * 作者   loufei
 * 时间   2018/10/11
 */
export default {
    data() {
        return {
            reports: []
        }
    },
    props: {},
    computed: {},
    methods: {
        setData(data) {
            this.$data.reports = data
        },
        getMaxRow(group) {
            const records = this.getRecords(group.tree)
            const keys = Object.keys(records)
            let max = 0

            keys.forEach((key) => {
                const size = records[key].length
                if (max === 0) {
                    max = size
                } else if (size > max) {
                    max = size
                }
            })
            return max
        },
        checkGenerateTemplate(catalog) {
            return !!catalog.lookAt
        },
        checkGenerateText(catalog) {
            return !!catalog.dataSetKey
        },
        checkGenerateTd(group, catalog, r_index) {
            const record = this.getRecord(group, catalog, r_index)
            return !!record
        },
        getRecord(group, catalog, r_index) {
            const records = this.getRecords(group.tree)
            return records[catalog.dataSetKey || catalog.lookAt][r_index - 1]
        },
        getColumn(group, catalog, r_index) {
            if (catalog.dataSetKey) {
                const record = this.getRecord(group, catalog, r_index)
                if (record) {
                    return record[catalog.column]
                }
            }
        },
        getColumnRowSpan(group, catalog, r_index) {
            const records = this.getRecords(group.tree)
            const record =
                records[catalog.dataSetKey || catalog.lookAt][r_index - 1]
            if (record) {
                return record._Row_Span_
            } else {
                return 1
            }
        },
        getRowSpan(node) {
            let total = 0
            if (node.children && node.children.length > 0) {
                node.children.forEach((node) => {
                    total += this.getRowSpan(node)
                })
                if (total === 0) {
                    total = node.children.length
                }
            }
            return total
        },
        getRecords(nodes, records = {}) {
            nodes.forEach((node) => {
                const table = node.dataSetKey
                if (!records[table]) {
                    records[table] = []
                }
                const rowSpan = this.getRowSpan(node) || 1
                records[table].push({
                    ...node.bean,
                    _Row_Span_: rowSpan
                })

                for (let i = 0; i < rowSpan - 1; i++) {
                    records[table].push(undefined)
                }

                if (node.children && node.children.length > 0) {
                    this.getRecords(node.children, records)
                }
            })
            return records
        }
    }
}
</script>

<style lang="scss"></style>
