<template>
    <div :id="_comId" :class="__class" v-show="__show" :style="{ width: width, height: height }">
        <!--头部，相册选择和格式选择-->
        <div :id="`${_comId}_uploader`" class="uploader">
            <div class="statusBar">
                <div class="btns" v-show="(fileCount > 0 || viewerFiles.length > 0) && !isDisabled">
                    <div :id="`${_comId}-filePicker2`" class="filePicker2"></div>
                    <div :class="`uploadBtn state-${this.state}`" @click="startUpload">{{ uploadBtnText }}</div>
                </div>
            </div>
            <div class="statusBar">
                <div class="progress" v-show="progress > 0">
                    <span class="text">{{ progress }}%</span>
                    <span class="percentage" :style="{ width: progress + '%' }"></span>
                </div>
                <div class="info" v-show="!!info">
                    <div v-html="info"></div>
                </div>
            </div>
            <div class="queueList">
                <div :id="`${_comId}_dndArea`" v-show="fileCount == 0 && viewerFiles.length == 0" class="placeholder">
                    <p>{{ placeholder }}</p>
                </div>

                <ul class="filelist" v-show="fileCount > 0">
                    <li :id="'file-' + file.id" :key="fIndex" v-for="(file, fIndex) in getFiles"
                        :class="`doc-bg ${file.ext} state-${file.state}`">
                        <p class="title" :title="file.name">{{ file.short_name }}</p>
                        <table class="imgWrap">
                            <tr :style="{ height: '100%' }">
                                <td>
                                    <img :title="file.name" :alt="file.name"
                                        :src="file.src || errorFileIcon(file) + '.png'" :data-file="file">
                                    <span v-show="!!file.info">{{ file.info }}</span>
                                </td>
                            </tr>
                        </table>
                        <p class="progress" v-show="file.progress != 0" :style="{ width: file.progress + '%' }">
                            <span></span>
                        </p>
                        <!--<div class="image-operate" v-show="tools&&file.docType=='IMAGE'"><span class="cancel">删除</span><span class="rotateRight">向右旋转</span><span class="rotateLeft">向左旋转</span></div>
                        <div class="image-operate" v-show="tools&&file.docType=='DOC'"><span class="cancel">删除</span><span class="download">下载</span><span class="viewer">预览</span></div>-->
                        <p class="error" v-show="!!file.error">{{ file.error }}</p>
                        <span class="success" v-show="file.complete"></span>
                    </li>
                </ul>
            </div>

            <div class="queueList" v-show="viewerFiles.length > 0">
                <ul class="filelist viewer">
                    <li :id="'file-' + file.id" :key="fIndex" v-for="(file, fIndex) in viewerFiles"
                        :class="`doc-bg state-${file.state}`" @click="openFile(file)">
                        <p class="title" :title="file.name">{{ file.name.substring(0, file.name.lastIndexOf('.')) }}</p>
                        <table class="imgWrap">
                            <tr :style="{ height: '100%' }">
                                <td>
                                    <span v-if="!isDisabled" @click.stop="removeServerFile(file)"
                                        style="position:absolute;top:2px;right:2px"><i class="fa fa-close"></i></span>
                                    <img :title="file.name" :alt="file.name"
                                        :src="finalServer + '/viewPic/' + file.id + '/' + thumb_size" :data-file="file"
                                        :onerror="'this.src=\'' + errorFileIcon(file) + '.png\';this.onerror=null'">
                                    <span v-show="!!file.info">{{ file.info }}</span>
                                </td>
                            </tr>
                        </table>
                        <!-- <div class="image-operate image" v-if="tools&&file.docType=='DOC'" v-show="file.showToolFlag">
                             <span class="cancel"><i class="fa fa-lg fa-trash"></i></span>
                             <span class="rotateRight"><i class="fa fa-lg fa-rotate-right"></i></span>
                             <span class="rotateLeft"><i class="fa fa-lg fa-rotate-left"></i></span>
                         </div>-->
                        <!-- <div class="image-operate" v-if="tools" v-show="file.showToolFlag">
                            <span class="cancel" v-if="!isDisabled" @click="removeServerFile(file)"><i class="fa fa-lg fa-trash"></i></span>
                            <span class="download" @click="download(file)"><i class="fa fa-lg fa-download"></i></span>
                            <span class="viewer" v-if="file.docType=='IMAGE'" @click="showAlbum(file)"><i class="fa fa-lg fa-play-circle-o"></i></span>
                            <span class="viewer" v-if="file.docType=='DOC'" @click="showDoc(file)"><i class="fa fa-lg fa-play-circle-o"></i></span>
                        </div> -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
/**
     * 上传组件
     * @module jgp-uploader
     * @example <jgp-uploader _allow="all" _object_id="uuid" _object_type="com.jgp.security.model.AdminUser"></jgp-uploader>
     */
import Common from '../../utils/common'
import Options from '../../utils/options'
import Check from 'check-types'
import WebUploader from 'webuploader';

const UploadBase = WebUploader.Base;

const ACCEPT = {
    image: {
        title: '图片',
        extensions: ['gif', 'jpg', 'jpeg', 'bmp', 'png']
    },
    doc: {
        title: '文档',
        extensions: ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'txt']
    },
    video: {
        title: '视频',
        extensions: ['mp4', 'avi']
    },
    audio: {
        title: '音频',
        extensions: ['mp3', 'ogg', 'wav']
    },
    zip: {
        title: '压缩文件',
        extensions: ['zip', 'rar', 'iso', 'tar']
    }
}

/**
     *
     * 项目   skin
     * 作者   loufei
     * 时间   2018/4/10
     */
export default {
    data: function () {
        return {
            files: {},
            viewerFiles: [],
            info: '', // 文件总体选择信息。
            uploadBtnText: '开始上传',
            state: 'pedding',
            formData: {},
            uploader: null,
            ratio: window.devicePixelRatio || 1,
            percentages: {},
            progress: 0,
            canGoOnSelect: false,
            showToolFlag: false,
            showAlbumFlag: false,
            fileSize: 0,
            existFiles: [],
            albumCurrent: 0,
            albumPics: [],
            removeCount: 0
        }
    },
    components: {},
    /**
         * @prop {String} _object_id 必填 否则无法上传 挂载对象id
         * @prop {String} _object_type 必填 否则无法上传 挂载对象className
         * @prop {String} _allow {默认:'all'} //image,doc,video,audio,all:平台支持的所有类型,自定义后缀:'mov,jpg', any:任何类型，没有特殊情况尽量不要使用
         * @prop {String} _server 提交后台地址，{默认:为附件系统地址} 自定义提交 附件管理将不起左右，正常业务开发不需要设置此项
         * @prop {String} _placeholder 拖拽框内的提示内容 {默认:'或将照片拖到这里，单次最多可选10个'}
         * @prop {String} _md5 {默认:true} 是否进行MD5文件唯一性校验,若后台未设置，此项生效，建议后台设置,建议开启，此项可以减少存储浪费
         * @prop {String} _total_num {默认:10} 一次性最多上传文件个数
         * @prop {String} _total_size {默认:200 * 1024 * 1024  200MB} 一次性最大上传总大小
         * @prop {String} _single_size {默认:50 * 1024 * 1024  50MB} 一次性单文件最大上传大小
         * @prop {String} _disabled 是否禁用 {默认:false} 是否禁用上传功能，若禁用则只能查看已上传的文件
         * @prop {String} _onfinished 所有文件上传完成之后调用
         */
    props: {
        '_object_id': String,
        '_object_type': String,
        '_object_params': String | Array,
        '_allow': {
            type: String,
            default: 'all' // image,doc,video,audio,all,any
        },
        '_name': {
            type: String,
            default: 'file'
        },
        '_server': {
            type: String,
            default: ''
        },
        '_swf': {
            type: String,
            default: '/static/plugin/Uploader.swf'
        },
        '_water': String,
        '_placeholder': {
            type: String,
            default: '或将照片拖到这里，单次最多可选300张'
        },
        '_md5': {
            type: String,
            default: 'false'
        },
        '_tools': {
            type: String,
            default: 'true'
        },
        '_total_num': {
            type: Number,
            default: 10
        }, // 10 个
        '_total_size': 200 * 1024 * 1024, // 200 M
        '_single_size': 50 * 1024 * 1024, // 50 M
        '_thumb_size': {
            type: String,
            default: '70,70'
        },
        '_disabled': {
            type: String,
            default: 'false'
        },
        '_onfinished': String,
        // 预览文件回调方法，配置此项后，所有默认预览方式失效
        '_view_fn': String,
        // 预览文档回调方法，配置此项后，文档（PDF）默认预览方式失效
        '_view_doc_fn': String,
        // 预览媒体回调方法
        '_view_media_fn': String
    },
    computed: {
        DOC_CONFIG() {
            return Options.getConfig().SERVER_PROP ? Options.getConfig().SERVER_PROP['attachment'] : undefined;
        },
        isDisabled() {
            return Common.toBool(this.disabled);
        },
        getFiles() {
            return Object.values(this.files).filter(file => file.state !== 'cancelled');
        },
        fileCount() {
            return this.getFiles.length;
        },
        md5Check() {
            return this.DOC_CONFIG ? this.DOC_CONFIG.md5Check : Common.toBool(this.md5);
        },
        finalServer() {
            return ((this.server.startsWith('http://') || this.server.startsWith('https://')) ? this.server : Common.BASE) + '/attachment/docApiController';
        },
        getObjInfo() {
            return { objId: this.object_id, objType: this.object_type }
        },
        accept() {
            let allow = ACCEPT;
            if (this.DOC_CONFIG) {
                allow = this.DOC_CONFIG.allow
            }
            if (this.allow === 'any' || this.allow === 'all' || allow[this.allow]) {
                if (this.allow === 'any') {
                    return {
                        title: '任何文件',
                        extensions: '*',
                        mimeTypes: '*'
                    }
                } else if (this.allow === 'all') {
                    let extensions = [];
                    for (let key in allow) {
                        extensions = extensions.concat(allow[key].extensions);
                    }
                    return {
                        title: '所有平台支持的文件类型',
                        extensions: extensions.join(','),
                        mimeTypes: Common.getMime(extensions).join(',')
                    }
                } else {
                    const ac = allow[this.allow];
                    return {
                        title: ac.title,
                        extensions: ac.extensions.join(','),
                        mimeTypes: Common.getMime(ac.extensions).join(',')
                    }
                }
            } else {
                const mimes = Common.getMime(this.allow.split(','));
                let m = [];
                for (let mime of mimes) {
                    if (mime) m.push(mime);
                }
                if (m.length === 0) {
                    return {
                        title: '自定义类型(' + this.allow + ')',
                        extensions: this.allow
                    }
                }
                return {
                    title: '自定义类型',
                    extensions: this.allow,
                    mimeTypes: mimes.join(',')
                }
            }
        },
        isSupportBase64() {
            var data = new Image();
            var support = true;
            data.onload = data.onerror = function () {
                if (this.width !== 1 || this.height !== 1) {
                    support = false;
                }
            }
            data.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            return support;
        },
        thumbnailWidth() {
            return 45 * this.ratio;
        },
        thumbnailHeight() {
            return 55 * this.ratio;
        }
    },
    methods: {
        errorFileIcon(file) {
            return ((process.env.NODE_ENV === "production") ? '/framework/pc2/img/' : '/img/') + file.ext.toUpperCase();
        },
        openFile(file) {
            if (this.view_fn) {
                Common.doFn(this.view_fn, file);
            } else {
                if (file.docType === 'IMAGE' || file.docType === 'VIDEO' || file.docType === 'AUDIO') {
                    this.showMedia(file);
                } else if (file.docType === 'DOC') {
                    this.showDoc(file);
                } else {
                    this.download(file);
                }
            }
        },
        create() {
            let _this = this;
            let multiple = true;
            if (this.total_num === 1) {
                multiple = false
            }
            let config = {
                pick: {
                    id: `#${this._comId}_dndArea`,
                    label: '点击选择文件&将文件拖拽到此',
                    multiple: multiple
                },
                formData: this.formData,
                dnd: `#${this._comId}_uploader`,
                paste: `#${this._comId}_uploader`,
                swf: this.swf,
                fileVal: this.name,
                chunked: true,
                chunkSize: 31457280, // 30M
                server: this.finalServer + '/upload',
                accept: this.accept,
                duplicate: false,
                disableGlobalDnd: true,
                compress: false,
                fileNumLimit: this.total_num,
                fileSizeLimit: this.total_size, // 200 M
                fileSingleSizeLimit: this.single_size // 50 M
            }
            if (this.isDisabled) {
                return;
            }

            this.uploader = WebUploader.create(config);
            this.uploader.onFileQueued = (file) => {
                _this.fileSize += file.size;
                _this.addFile(file);
                _this.setState('ready');
                _this.updateStatus();
            };
            this.uploader.onUploadStart = (file) => {
                file['uuid'] = Common.uuid(32);
            }

            this.uploader.onUploadBeforeSend = (object, data, headers) => {
                const wuFile = object.file;
                data['object_id'] = _this.object_id;
                data['object_type'] = _this.object_type;
                data['water'] = _this.water;
                data['object_params'] = Check.array(_this.object_params) ? JSON.stringify(_this.object_params) : _this.object_params;
                data['chuck_md5'] = object.chuck_md5;
                data['uuid'] = wuFile.uuid;
                if (_this.md5Check) {
                    data['md5'] = wuFile.md5;
                }
            }

            this.uploader.onUploadProgress = (file, percentage) => {
                _this.$set(this.files[file.id], 'progress', percentage);
                _this.percentages[file.id][1] = percentage;
                _this.updateTotalProgress();
            };

            this.uploader.on('all', (type) => {
                switch (type) {
                    case 'uploadFinished':
                        _this.setState('confirm');
                        break;

                    case 'startUpload':
                        _this.setState('uploading');
                        break;

                    case 'stopUpload':
                        _this.setState('paused');
                        break;
                }
            });

            this.uploader.onFileDequeued = (file) => {
                _this.fileSize -= file.size;
                if (!(_this.fileCount - 1)) {
                    _this.setState('pedding');
                }
            };

            this.uploader.onUploadSuccess = (file, response) => {
                if (response && response.data && response.data.fileId) {
                    _this.existFiles.push(response.data.fileId);
                    _this.$set(this.files[file.id], 'fileId', response.data.fileId);
                    // console.log('------onUploadSuccess------');
                }
            };
            // 整个上传任务结束后，如果existFiles中有数据说明文件存在,只需要挂载
            this.uploader.onUploadFinished = () => {
                if (_this.object_id && _this.object_type && _this.existFiles.length > 0) {
                    this.updateMountInfo(_this.object_id, _this.object_type, () => {
                        if (_this.onfinished) {
                            Common.doFn(_this.onfinished, {
                                existFiles: Object.assign([], this.existFiles)
                            });
                        }
                        let files = _this.uploader.getFiles('complete');
                        for (let i = 0; i < files.length; i++) {
                            let file = files[i];
                            _this.removeFile(file);
                        }

                        _this.$set(_this, 'existFiles', []);
                        // _this.$set(_this, 'files', {});
                        _this.$set(_this, 'percentages', {});
                        _this.loadViewerFiles();
                    })
                    return;
                }
                if (_this.onfinished) {
                    Common.doFn(_this.onfinished, {
                        existFiles: Object.assign([], _this.existFiles)
                    });
                }
                _this.loadViewerFiles(_this.existFiles);

                let files = _this.uploader.getFiles('complete');
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    _this.removeFile(file);
                }
                // _this.$set(_this, 'existFiles', []);
                // _this.$set(_this, 'files', {});
                _this.$set(_this, 'percentages', {});
                _this.updateStatus();
            };

            this.uploader.onError = function (code) {
                switch (code) {
                    case 'Q_EXCEED_NUM_LIMIT':
                        Common.warn('最多上传' + _this.total_num + '个文件');
                        break;

                    case 'Q_EXCEED_SIZE_LIMIT':
                        Common.warn('文件总大小超出' + _this.total_size);
                        break;

                    case 'Q_TYPE_DENIED':
                        Common.warn('文件类型不符合要求');
                        break;
                }
            };

            this.uploader.onUploadComplete = (file) => {
                // console.log('------onUploadComplete------');
            }

            this.uploader.addButton({
                id: `#${_this._comId}-filePicker2`,
                label: '添加文件'
            });
        },
        setState(val) {
            var stats;
            let _this = this;

            if (val === this.state) {
                return;
            }

            this.state = val;
            switch (_this.state) {
                case 'pedding':
                    _this.canGoOnSelect = true;
                    _this.uploader.refresh();
                    break;

                case 'ready':
                    _this.canGoOnSelect = true;
                    _this.uploader.refresh();
                    break;

                case 'uploading':
                    _this.canGoOnSelect = true;
                    break;

                case 'paused':
                    _this.canGoOnSelect = true;
                    break;

                case 'confirm':
                    _this.canGoOnSelect = false;
                    stats = _this.uploader.getStats();
                    if (stats.successNum && !stats.uploadFailNum) {
                        _this.setState('finish');
                        return;
                    }
                    break;
                case 'finish':
                    stats = _this.uploader.getStats();
                    if (stats.successNum) {
                        Common.alert('上传成功');
                    } else {
                        // 没有成功的图片，重设
                        _this.setState('done');
                        location.reload();
                    }
                    break;
            }
            this.updateStatus();
        },
        addFile(file) {
            let _file = file;
            let _this = this;
            file['short_name'] = file.name.substring(0, file.name.lastIndexOf('.'));
            _this.$set(_this.files, _file.id, file);
            let showError = function (code) {
                let text = '';
                switch (code) {
                    case 'exceed_size':
                        text = '文件大小超出';
                        break;

                    case 'interrupt':
                        text = '上传暂停';
                        break;

                    default:
                        text = '上传失败，请重试';
                        break;
                }
                _this.$set(_this, 'state', 'error');
                _this.$set(_this, 'info', text);
            };

            if (file.getStatus() === 'invalid') {
                showError(file.statusText);
            } else {
                if (ACCEPT['image'].extensions.includes(file.ext)) {
                    _this.$set(_this.files[_file.id], 'info', '正在预览...');
                    _this.uploader.makeThumb(file, function (error, src) {
                        if (error) {
                            _this.$set(_this.files[file.id], 'error', '不能预览');
                            return;
                        }

                        if (_this.isSupportBase64) {
                            _this.$set(_this.files[file.id], 'src', src);
                        }
                        _this.$set(_this.files[_file.id], 'info', undefined);
                    }, _this.thumbnailWidth, _this.thumbnailHeight);
                }

                _this.percentages[file.id] = [file.size, 0];
                _file['rotation'] = 0;
            }

            file.on('statuschange', function (cur, prev) {
                if (prev === 'progress') {
                    _this.progress = 0;
                } else if (prev === 'queued') {
                }

                let tempFile = {};

                // 成功
                if (cur === 'error' || cur === 'invalid') {
                    showError(file.statusText);
                    _this.percentages[file.id][1] = 1;
                } else if (cur === 'interrupt') {
                    showError('interrupt');
                } else if (cur === 'queued') {
                    tempFile['info'] = undefined;
                    tempFile['progress'] = 1;
                    _this.percentages[file.id][1] = 0;
                } else if (cur === 'progress') {
                    tempFile['info'] = undefined;
                    tempFile['progress'] = 1;
                } else if (cur === 'complete') {
                    tempFile['complete'] = true;
                    tempFile['progress'] = 0;
                }
                tempFile['state'] = cur;

                _this.$set(_this.files, _file.id, Object.assign({}, _this.files[_file.id], tempFile));
            });
        },

        updateTotalProgress() {
            let _this = this;
            this.$nextTick(() => {
                var loaded = 0;
                var total = 0;
                var percent;
                for (let k in _this.percentages) {
                    const v = _this.percentages[k];
                    total += v[0];
                    loaded += v[0] * v[1];
                }

                percent = total ? loaded / total : 0;

                _this.$set(_this, 'progress', Math.round(percent * 100))
                _this.updateStatus();
            })
        },
        updateStatus() {
            var text = '';
            let _this = this;
            const stats = this.uploader.getStats();
            if (_this.state === 'ready') {
                text = '选中' + _this.fileCount + '个文件，共' +
                    WebUploader.formatSize(_this.fileSize) + '。';
            } else if (_this.state === 'confirm') {
                if (stats.uploadFailNum === 0) {
                    text = '已成功上传' + stats.successNum + '个文件，' +
                        stats.uploadFailNum + '个文件上传失败，重新上传失败文件</a>'
                }
            } else if (stats.successNum > 0) {
                let count = stats.successNum;
                if (_this.removeCount) {
                    count -= _this.removeCount
                }
                text = '共已上传' + count + '个';

                if (stats.uploadFailNum) {
                    text += '，失败' + stats.uploadFailNum + '个';
                }
            }
            _this.info = text;
        },
        loadViewerFiles(fileIds) {
            let _this = this;
            let url = `${this.finalServer}/queryFileByObject`;
            let postParam = {};

            if (fileIds && fileIds.length > 0) {
                postParam['fileIds'] = fileIds.join(',');
                url = `${this.finalServer}/queryFileByFileIds`;
            } else if ((!!_this.object_id && !!_this.object_type)) {
                postParam['objectId'] = _this.object_id;
                postParam['objectType'] = _this.object_type;
            }

            Common.post(url, postParam, (result) => {
                if (result.data.files) {
                    result.data.files.forEach(file => {
                        file['showToolFlag'] = false;
                    })
                    _this.$set(_this, 'viewerFiles', result.data.files);
                }
            }, null, false)
        },
        removeFile(file) {
            let _this = this;
            if (_this.files[file.id]) {
                delete _this.files[file.id];
                delete _this.percentages[file.id];
                _this.uploader.removeFile(file, true);
            }
            console.log(this.getFiles)
            _this.updateStatus();
        },
        startUpload() {
            let _this = this;
            /* if (!!this.object_id && !!this.object_type) {
                 this.$nextTick(() => {
                 this.uploader.upload();
                 })
                 } else {
                 Common.error('配置不正确:挂载对象信息不全！');
                 } */
            this.$nextTick(() => {
                _this.uploader.upload();
            })
        },
        /**
             * @function
             * @param objectId 挂载对象id
             * @param objectType 挂载对象类型
             * @param callback 回调
             * @desc 将已上传的文件挂载到到对象上
             */
        updateMountInfo(objectId, objectType, callback) {
            let _this = this;
            Common.updateMountInfo(`${_this.finalServer}/updateMountInfo`, objectId, objectType, _this.existFiles, (result) => {
                if (callback && Check.function(callback)) {
                    callback(result);
                    _this.$set(_this, 'existFiles', []);
                }
            })
        },
        download(file) {
            var a = document.createElement('a');
            var url = `${this.finalServer}/download/${file.id}`;
            a.href = url;
            a.click()
        },
        removeServerFile(file) {
            this.removeFile(file);
            if (!!this.object_id && !!this.object_type) {
                Common.confirm('确定删除?', () => {
                    Common.post(`${this.finalServer}/remove/${this.object_id}/${this.object_type}/${file.id}`, {}, (result) => {
                        Common.alert("删除成功!");
                        this.loadViewerFiles();
                        this.removeCount++;
                        this.updateStatus();
                    })
                })
            }
        },
        showMedia(file) {
            if (file.docType !== 'IMAGE' && file.docType !== 'VIDEO' && file.docType !== 'AUDIO') return;
            this.showAlbumFlag = !this.showAlbumFlag;
            this.albumPics = [];
            let index = 1;
            this.viewerFiles.forEach(viewFile => {
                if (viewFile.docType === 'IMAGE') {
                    this.albumPics.push({
                        id: viewFile.id,
                        isMedia: false,
                        url: this.finalServer + '/viewPicWater/' + viewFile.id
                    })
                } else if (viewFile.docType === 'VIDEO') {
                    this.albumPics.push({
                        id: viewFile.id,
                        isMedia: true,
                        url: this.finalServer + '/play/' + viewFile.id
                    })
                }
            });
            this.albumPics.forEach((pic, i) => {
                if (pic.id === file.id) {
                    index = i;
                }
            })

            this.albumCurrent = index;
            if (this.view_media_fn) {
                Common.doFn(this.view_media_fn, { currentFile: file, albumPics: this.albumPics, albumCurrent: this.albumCurrent });
            } else {
                Common.openAlbum(this.albumPics, this.albumCurrent);
            }
        },
        showDoc(file) {
            if (this.view_doc_fn) {
                Common.doFn(this.view_doc_fn, file);
            } else {
                if (file.ext.toUpperCase() === 'PDF') {
                    this.showPdf(file);
                } else {
                    this.download(file);
                }
            }
        },
        showPdf(file) {
            window.open(this.finalServer + '/view-doc/' + file.id + '.pdf')
        },
        setWater(water) {
            this.water = water;
        }
    },
    watch: {
        object_id() {
            this.create();
            this.loadViewerFiles();
            if (this.uploader) {
                this.uploader.reset();
                this.updateStatus();
            }
        },
        object_Type() {
            this.create();
            this.loadViewerFiles();
            if (this.uploader) {
                this.uploader.reset();
                this.updateStatus();
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {

    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {
        let _this = this;
        WebUploader.Uploader.register({
            'before-send': 'beforeSend',
            'before-send-file': 'beforeSendFile'
        }, {
            // 分片上传之前
            beforeSend: function (block) {
                if (_this.md5Check && _this.files[block.file.id]) {
                    const deferred = UploadBase.Deferred();
                    (new WebUploader.Uploader()).md5File(block.file, block.start, block.end)
                        .then(function (chunk_md5) {
                            block['chuck_md5'] = chunk_md5;
                            deferred.resolve();
                        });
                    return deferred.promise();
                }
            },
            // 文件整体上传之前
            beforeSendFile: (file) => {
                if (_this.md5Check && _this.files[file.id]) {
                    const deferred = UploadBase.Deferred();
                    (new WebUploader.Uploader()).md5File(file)
                        .progress((percentage) => {
                            Common.loading(true, true)
                        })
                        .then((md5) => {
                            file['md5'] = md5;
                            Common.post(`${_this.finalServer}/md5Check/${md5}`, {}, (result) => {
                                file['exist'] = result.flag;
                                if (file['exist']) {
                                    // 跳过md5校验存在的文件
                                    _this.uploader.skipFile(file);
                                    _this.existFiles.push(result.data.fileId);
                                    _this.updateStatus();
                                }
                                Common.loading(false, true)
                                deferred.resolve();
                            }, (error) => {
                                console.error('beforeSendFile:error:', error)
                                Common.loading(false, true)
                                deferred.reject();
                            }, false)
                        });
                    return deferred.promise();
                }
            }
        });
    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {

    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.create();
        this.loadViewerFiles();
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
