<template>
    <div v-if="!!args._wrap" :id="_comId" :class="__class +' '+ getFilter" ref="colDom" v-tooltip="tipOption">
        <div ref="wrapContainer"></div>
    </div>
    <div :id="_comId" :class="__class +' '+ getFilter" ref="colDom" v-tooltip="tipOption" v-else>
        <span :style="getStyle" v-if="ifUnderLine" :class="ifUnderLine?'underline':undefined">{{getValue}}</span>
        <span :style="getStyle" v-else>{{getValue}}</span>
    </div>
</template>

<script>
/**
     * 自定义列
     * @module jgp-grid-col
     * @example  <jgp-grid-col _name="title" _filter="filterFn" _style="{'font-weight':'bold','color':'red'}" _fns="xxx" _underline="true"></jgp-grid-col>
     * @example  <jgp-grid-col _name="date" _fns="['xxx2','xxx3']"
     _wrap="<button class='button button-primary button-raised button-tiny button-rounded'>[content]</button>
     &nbsp;&nbsp;
     <button class='button button-primary button-raised button-tiny button-rounded'>[content]</button>">
     </jgp-grid-col>
     */
import Vue from 'vue'
import Check from 'check-types'
import Base from '../Base'
import Common from '../../utils/common'

/**
     *
     * 项目   jgp-front-pc
     * 作者   loufei
     * 时间   2018/8/29
     */
export default {
    data() {
        return {}
    },
    /**
         * @prop {String} _name 列标识 对应实体中的属性
         * @prop {String} _style 样式表 数据结构为json结构 注意：设置了_wrap _style无效
         * @prop {String} _fns 操作。若果内部元素有多个 可设为操作数组 ['fn1','fn2']，有一个直接写操作方法名字符串 回调参数为 id: jgp-grid-col 的组件id，obj:组件对象,record:整条记录数据
         * @prop {String} _wrap 包装 用于把列内容包裹起来
         * 比如：_wrap="<button class='button button-primary button-raised button-tiny button-rounded'>[content]</button>"
         * 是用button包裹。注意：列实际内容用[content]代替
         * @prop {String} _underline 下划线 注意：设置了_wrap _underline无效
         *
         */
    props: {
        '_name': String,
        '_value': String | Boolean | Number,
        '_row_no': Number,
        '_col_no': Number,
        '_style': String | Object,
        '_filter_class': String,
        '_filter_data': String,
        '_filter_render': String,
        '_transform_value': String | Function,
        '_show_tip': String,
        '_fns': String,
        '_fns_mode': {
            type: String, // click change
            default: 'click'
        },
        '_wrap': String,
        '_underline': {
            type: String
        },
        '_args': Object,
        '_record': Object,
        '_display_record': Object
    },
    watch: {
        wrap() {
            this.renderWrap();
        }
    },
    computed: {
        ifUnderLine() {
            return Common.toBool(this.args._underline);
        },
        getStyle() {
            return Common.toJson(this.args._style);
        },
        showTip() {
            return Common.toBool(this.args._show_tip);
        },
        getFns() {
            let fns = Common.toJson(this.args._fns);
            return Check.undefined(fns) ? this.args._fns : fns;
        },
        getFnMode() {
            return this.args._fns_mode || 'click';
        },
        callbackArgs() {
            const _this = this;
            return {
                id: _this._comId,
                rowNo: _this.row_no,
                colNo: _this.col_no,
                obj: _this,
                value: _this._record.dataMap[_this.args._name],
                record: _this._record.dataMap
            }
        },
        getFilter() {
            if (this.args._filter_render) {
                return Common.doFn(this.args._filter_render, this.callbackArgs);
            } else if (this.args._filter_data && Common.isInArray(this._record.dataMap.id, Common.toJson(this.args._filter_data))) {
                return this.args._filter_class;
            } else {
                return '';
            }
        },
        getValue() {
            if (this.args._transform_value) {
                return Common.doFn(this.args._transform_value, this.callbackArgs);
            } else {
                return this.value;
            }
        },
        tipOption() {
            if (this.showTip || this.showTip === undefined) {
                return { classes: ['grid-tip-info'], content: this.getValue, trigger: 'hover' }
            } else {
                return undefined;
            }
        }
    },
    methods: {
        /**
             * @name setWrap
             * @function
             * @desc 设置wrap
             */
        setWrap(wrap) {
            this.$set(this.args, '_wrap', wrap);
        },
        /**
             * @name setStyle
             * @function
             * @desc 设置style 当设置_wrap后 此方法无效
             */
        setStyle(style) {
            this.$set(this.args, '_style', style);
        },
        bindFn() {
            let _this = this;
            let children = _this.$refs.colDom.children[0].children;
            let args = this.callbackArgs;
            if (!_this.getFns || _this.getFns.length === 0) {
                return;
            }

            if (children && children.length > 1) {
                for (let i = 0; i < children.length; i++) {
                    children[i]['on' + _this.getFnMode] = (event) => {
                        args['event'] = event;
                        args['dom'] = event.target;
                        event.stopPropagation();
                        if (Check.array(_this.getFns)) {
                            if (!_this.getFns[i]) return false;
                            _this.doGridColAction(_this.getFns[i], args);
                        } else {
                            _this.doGridColAction(_this.getFns, args);
                        }
                    }
                }
            } else if (children && children.length === 1) {
                children[0]['on' + _this.getFnMode] = (event) => {
                    args['event'] = event;
                    args['dom'] = event.target;
                    event.stopPropagation();
                    if (Check.array(_this.getFns)) {
                        if (!_this.getFns[0]) return false;
                        _this.doGridColAction(_this.getFns[0], args);
                    } else {
                        _this.doGridColAction(_this.getFns, args);
                    }
                }
            } else {
                _this.$refs.colDom['on' + _this.getFnMode] = (event) => {
                    args['event'] = event;
                    args['dom'] = event.target;
                    event.stopPropagation();
                    if (Check.array(_this.getFns)) {
                        if (!_this.getFns[0]) return false;
                        _this.doGridColAction(_this.getFns[0], args);
                    } else {
                        _this.doGridColAction(_this.getFns, args);
                    }
                }
            }
        },
        doGridColAction(fn, args) {
            this.$emit('onColClick', {
                fn: fn,
                args: args
            });
        },
        regYh(tpl) {
            var reg = /\[(.*?)\]/;
            if (reg.test(tpl)) {
                tpl = tpl.replace(RegExp.$1, RegExp.$1.replace(/\\\'/g, "\""));
            }
            return tpl;
        },
        regTpl(tpl, i) {
            const _this = this;
            // var reg1 = /\[(.*?)\]/g;
            var reg2 = /(\<jgp-[a-z].*?\b)/
            /* if (reg1.test(tpl)) {
                let tpls = tpl.match(reg1);
                for (let x = 0; x < tpls.length; x++) {
                    tpl = tpl.replace(tpls[x], _this.regYh(tpls[x]));
                }
            } */
            if (_this.getFns[i]) {
                if (reg2.test(tpl)) {
                    tpl = tpl.replace(RegExp.$1, RegExp.$1 + " @" + _this.getFnMode + "='" + _this.getFnMode + i + "'");
                }
            }
            return tpl;
        },
        renderWrap() {
            const _this = this;
            let eventArgs = this.callbackArgs;
            if (_this.args._wrap) {
                let tpl = _this.args._wrap;
                let regRadio = /(\<jgp-radio.*?\/\>)/g;
                if (regRadio.test(tpl)) {
                    tpl = tpl.replace(/\[content\]/g, _this._record.dataMap[_this.args._name]);
                } else {
                    tpl = tpl.replace(/\[content\]/g, _this.getValue);
                }
                tpl = tpl.replace(/\\\'/g, '"');
                var reg1 = /(\<jgp-[a-z].*?\/\>)/g;
                let multi = false;
                let vm;
                if (reg1.test(tpl)) {
                    let tpls = tpl.match(reg1);
                    if (tpls.length > 0) {
                        multi = true;
                    }
                    for (let i = 0; i < tpls.length; i++) {
                        tpl = tpl.replace(tpls[i], _this.regTpl(tpls[i], i));
                    }

                    let methods = {};
                    for (let m = 0; m < _this.getFns.length; m++) {
                        methods['change' + m] = function(args) {
                            eventArgs['event'] = event;
                            eventArgs['dom'] = event.target;
                            eventArgs['obj'] = multi ? this.$children[m] : this;
                            eventArgs['value'] = args;
                            Common.doFn(_this.getFns[m], eventArgs);
                        }
                        methods['click' + m] = function() {
                            eventArgs['event'] = event;
                            eventArgs['dom'] = event.target;
                            eventArgs['obj'] = multi ? this.$children[m] : this;
                            Common.doFn(_this.getFns[m], eventArgs);
                        }
                        methods['focus' + m] = function(args) {
                            eventArgs['event'] = event;
                            eventArgs['dom'] = event.target;
                            eventArgs['obj'] = multi ? this.$children[m] : this;
                            eventArgs['value'] = args;
                            Common.doFn(_this.getFns[m], eventArgs);
                        }
                        methods['blur' + m] = function(args) {
                            eventArgs['event'] = event;
                            eventArgs['dom'] = event.target;
                            eventArgs['obj'] = multi ? this.$children[m] : this;
                            eventArgs['value'] = args;
                            Common.doFn(_this.getFns[m], eventArgs);
                        }
                    }

                    vm = new Vue({
                        parent: _this,
                        mixins: [Base],
                        template: tpl,
                        methods: methods
                    });
                } else {
                    vm = new Vue({
                        parent: _this,
                        mixins: [Base],
                        template: tpl,
                        mounted() {
                            vm.$nextTick(() => {
                                _this.bindFn();
                            })
                        }
                    });
                }
                vm.$mount(_this.$refs.wrapContainer);
            } else {
                _this.bindFn();
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {

    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {

    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {

    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.renderWrap();
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>

<style>

</style>
