<script>
/**
 * 基础
 * @module ./components/base
 */
import Common from "../utils/common";
import Options from "../utils/options";

export default {
    data() {
        const _this = this;
        let cType = Common.transformName(
            _this.$options._componentTag || _this.$options.name
        );
        let data = {};

        var propsKeys = Object.keys(_this.$options.props);
        propsKeys.forEach(prop => {
            if (prop !== "__proto__" && prop.startsWith("_")) {
                var dataName = prop.substring(1);
                if (_this[prop] !== undefined) {
                    data[dataName] = _this[prop];
                } else if (_this.$options.props[prop].default != null) {
                    data[dataName] = _this.$options.props[prop].default;
                } else if (data[dataName] === undefined) {
                    data[dataName] = "";
                }
            }
        });

        let config = data.config_id
            ? Options.option(cType, data.config_id)
            : {};
        let configKeys = Object.keys(config || {});

        configKeys.forEach(key => {
            if (config[key] !== undefined) {
                data[key] = config[key];
            }
        });

        data["cType"] = cType;
        data["global"] = Options.global();
        if (data["id"]) {
            data["id_"] = data["id"];
            delete data["id"];
        }
        data["__exist_flag"] = true;
        return data;
    },
    computed: {
        _comId() {
            return this.id_ ? this.id_ : this.cType + "-" + Common.rndNum(6);
        },
        __class() {
            this.addClass(this.cType);
            return this.class;
        },
        __show() {
            return Common.toBool(this.is_show) !== false;
        },
        __existFlag() {
            return this.$data.__exist_flag;
        }
    },
    /**
     * @prop {String} _id 图片地址
     * @prop {String} _config_id 配置id 优先级 组件级>config级>config_id级别
     * @prop {String} _config 配置对象 优先级 组件级>config级>config_id级别
     * @prop {String} _class 样式表
     * @prop {String} _style 样式
     * @prop {String} _is_show 是否显示
     */
    props: [
        "_id",
        "_config_id",
        "_config",
        "_class",
        "_style",
        "_is_show",
        "_height",
        "_width",
        "_over_render",
        "origin"
    ],
    methods: {
        /**
         * @name addClass
         * @function
         * @param name 样式类名
         * @desc  增加样式表
         */
        addClass(name) {
            if (this.class.indexOf(name) !== -1) return;
            name += " " + this.class;
            this.class = name.trim();
        },
        /**
         * @name removeClass
         * @function
         * @param name 样式类名
         * @desc  删除样式表
         */
        removeClass(name) {
            this.class = this.class.replace(name, "").replace(/\s+/gi, " ");
        },
        hasClass(name) {
            return this.class.indexOf(name) !== -1;
        },
        remove() {
            if (this.$parent) {
                this.$parent.$children.splice(
                    this.$parent.$children.indexOf(this),
                    1
                );
            }
            this.$data.__exist_flag = false;
        },
        /**
         * @name show
         * @function
         * @param flag 显示隐藏 标志 true/false
         * @desc  设置显示隐藏
         */
        show(flag) {
            this.is_show = flag;
        },
        getHeight() {
            return this.$el.offsetHeight;
        },
        getWidth() {
            return this.$el.offsetWidth;
        },
        setHeight(val) {
            this.height = val;
        },
        setWidth(val) {
            this.width = val;
        },
        /**
         * @name getNestedComById
         * @function
         * @param id 组件id
         * @desc  根据id获取子组件
         */
        getNestedComById(id) {
            let childrenComs = [];
            for (let child of this.$children) {
                if (child._comId === id) {
                    childrenComs.push(child);
                } else {
                    childrenComs.concat(child.getNestedComById(id));
                }
            }
            if (childrenComs.length === 1) {
                return childrenComs[0];
            }
        },
        /**
         * @name getNestedComByType
         * @function
         * @param types 类型 标签名 jgp-btn jgp-panel
         * @desc  根据组件类型获取子组件
         */
        getNestedComByType(...types) {
            let childrenComs = [];
            for (let child of this.$children) {
                if (types.indexOf(child.cType) > -1) {
                    childrenComs.push(child);
                } else {
                    childrenComs.concat(child.getNestedComByType(...types));
                }
            }
            return childrenComs;
        },
        /**
         * @name attr
         * @function
         * @param name 属性名
         * @param value 值
         * @desc  设置或获取标签属性
         * @example attr('_name','aaaa') 设置name属性为 aaaa
         * @example attr('_name') 获取name属性的值
         */
        attr(name, value) {
            if (value) {
                if (name[0] === "_") name = name.replace("_", "");
                this.$set(this, name, value);
            } else {
                return this[name];
            }
        }
    },
    created() {
        var that = this;
        /** 所有组件定义的props名称数组 */
        var propsKeys = Object.keys(that.$options.props || {});

        propsKeys.forEach(function (prop) {
            if (prop !== "__proto__" && prop.startsWith("_")) {
                // 若使用mixins方法导入本代码，则本函数会 先于 组件内data函数执行！
                var dataName = prop.substring(1);
                if (dataName === "id") {
                    dataName = "id_";
                }
                // 监听所有props属性
                that.$watch(
                    prop,
                    function (newVal, oldVal) {
                        that[dataName] = newVal; // 将组件外变更的prop同步到组件内的p_prop变量中
                    },
                    { deep: true }
                );
                // [监听所有属性映射到组件内的变量]
                that.$watch(
                    dataName,
                    function (newVal, oldVal) {
                        that.$emit(
                            Common.EMIT.PROPS_CHANGE,
                            prop,
                            newVal,
                            oldVal
                        ); // 将组件内prop通知给组件外(调用方)
                    },
                    { deep: true }
                );
            }
        });
        Options.com(this.cType, this._comId, this);
    },
    mounted() { },
    beforeUpdate() { },
    updated() {
        this.$nextTick(() => {
            Common.doFn(this.over_render, this);
        });
    }
};
</script>
