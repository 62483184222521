<template>
    <div :id="_comId" :class="__class" :style="{width:width?width:'100%',height:height?height:'100%'}" v-show="__show">
        <div v-for="(r,r_index) in getRow" :key="r_index" class="row clearfix"
            :style="{'height':rowHeightArr[r_index]}">
            <div v-for="(c,c_index) in getCol" :key="c_index" class="col clearfix"
                :style="{height:'100%','width':colWidthArr[c_index]}">
                <!-- @slot 行列号，比如1行1列 => 1-1 -->
                <slot :name="`${r_index+1}-${c_index+1}`">
                    <div class="aux" v-if="getAux">
                        <span>{{r_index + 1}}-{{c_index + 1}}</span>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Common from '../../utils/common'
/**
 * 文本组件
 *
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpDiv
 */
export default {
    props: {
        /**
         * 列 为数组字符串{默认:['100']}<br>
         * 数组字符串的个数代表列数。数值代表宽度不带单位，单位由_cunit 确定，'*'代表剩下宽度，若有多个星号占位，则为剩下宽度平均值
         */
        "_col": {
            type: String,
            default: "['100']"
        },
        /**
         * 行 为数组字符串{默认:['100']}<br>
         * 数组字符串的个数代表行数。数值代表高度不带单位，单位由_runit 确定，'*'代表剩下宽度，若有多个星号占位，则为剩下宽度平均值
         */
        "_row": {
            type: String,
            default: "['100']"
        },
        /**
         * 列宽度单位{单位:px|%，默认:%}
         */
        "_cunit": {
            type: String,
            default: '%'
        },
        /**
         * 行高度单位{单位:px|%，默认:%}
         */
        "_runit": {
            type: String,
            default: '%'
        },
        /**
         * 辅助线
         */
        "_aux": {
            type: String,
            default: 'false'
        }
    },
    computed: {
        getCol() {
            return Common.toJson(this.col)
        },
        getRow() {
            return Common.toJson(this.row)
        },
        getAux() {
            if (this.row || this.col) {
                return Common.toBool(this.aux)
            }
        },
        rowHeightArr() {
            let rowSum = 0;
            let rowPlaceholderCount = this.getRow.filter((r) => {
                return r === '*';
            }).length;

            this.getRow.every((r) => {
                let rn = Number(r);
                rowSum += !isNaN(rn) ? rn : 0;
                return true;
            })

            let rowPlaceholderHeight = `calc((100% - ${rowSum}${this.runit}) / ${rowPlaceholderCount !== 0 ? rowPlaceholderCount : 1})`;
            this.getRow.every((r, index, array) => {
                if (r === '*') { this.getRow.splice(index, 1, rowPlaceholderHeight); } else { this.getRow.splice(index, 1, array[index] + this.runit) }
                return true;
            })
            return this.getRow;
        },
        colWidthArr() {
            let colSum = 0;
            let colPlaceholderCount = this.getCol.filter((c) => {
                return c === '*';
            }).length;

            this.getCol.every((c) => {
                let cn = Number(c);
                colSum += !isNaN(cn) ? cn : 0;
                return true;
            })

            let colPlaceholderWidth = `calc((100% - ${colSum}${this.cunit}) / ${colPlaceholderCount !== 0 ? colPlaceholderCount : 1})`;
            this.getCol.every((c, index, array) => {
                if (c === '*') {
                    this.getCol.splice(index, 1, colPlaceholderWidth)
                } else {
                    this.getCol.splice(index, 1, array[index] + this.cunit)
                }
                return true;
            })
            return this.getCol;
        }
    },
    methods: {},
    /*
     在实例初始化之后，数据观测 (data observer)
     和 event/watcher 事件配置之前被调用。
     */
    beforeCreate() {
    },
    /*
     在实例创建完成后被立即调用。在这一步，实例已完成以下
     的配置：数据观测 (data observer)，属性和方法的运算，
     watch/event 事件回调。然而，挂载阶段还没开始，
     $ el 属性目前不可见。
     */
    created() {

    },
    /*
     在挂载开始之前被调用：相关的 render 函数首次被调用。
     */
    beforeMount() {
    },
    /*
     el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
     如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
     vm.$ el 也在文档内。

     注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
     等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
     */
    mounted() {
    },
    /*
     数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
     比如手动移除已添加的事件监听器。
     */
    beforeUpdate() {
    },
    /*
     由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

     当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
     然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
     用计算属性或 watcher 取而代之。

     注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
     绘完毕，可以用 vm.$ nextTick 替换掉 updated：
     */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
