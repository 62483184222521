<template>
    <div v-if="!needRenderForm" :id="_comId" :class="`${__class} layout-${cols} label-align-${label_align}`"
        :style="{ width: width, height: height }" v-show="__show">
        <form :action="action" :method="method" :name="_comId" @submit.prevent="validForm" ref="form">
            <slot></slot>
            <jgp-hidden :key="index" v-for="(h, index) in hiddens" :_name="h.name" :_value="h.value"
                :_submit="h.submit"></jgp-hidden>
        </form>
    </div>
    <div v-else :id="_comId" :class="`${__class} layout-${formInfo.col} label-align-${label_align}`"
        :style="{ width: width, height: height }" v-show="__show">
        <form :action="action" :method="method" :name="_comId" @submit.prevent="validForm" ref="form">
            <component v-for="attr in formAttrs" :is="getInputType(attr.type)" :key="attr.id" :_id="attr.id"
                :_url="attr.dropType == 'REST' ? attr.dataSource : undefined" :_required="attr.required"
                :_col="attr.col" :_row="attr.row" :_mode="attr.type == 'DROP_TREE' ? 'tree' : 'list'"
                :_tree_show_root="false" :_label_width="attr.labelWidth" :_decimal="attr.decimal"
                :_format="getFormat(attr.type)" :_multi="getFormat(attr.type)"
                :_active_key="attr.dropType == 'ACTIVE_KEY' ? attr.dataSource : undefined" :_name="attr.name"
                :_label="attr.label" :_value="attr.value">
            </component>
        </form>
    </div>
</template>

<script>
// TODO xxxxx替换成store所属模块
import Check from 'check-types'
import Common from '../../utils/common';
/**
 * 文本组件
 *
 * @author 娄飞 【Gavin Lou】
 * @displayName JgpForm
 */
export default {
    validator: null,
    data() {
        return {
            formInfo: {},
            formAttrs: [],
            validData: {
                errors: {},
                rules: {}
            },
            keyValue: {},
            hiddens: [],
            els: []
        }
    },
    /**
         * @prop {String} _action 提交地址 (html 中 form action)
         * @prop {String} _method 提交方式:POST,GET (html 中 form method)
         * @prop {String} _cols 布局 可选24列或12列 默认:12(既12列布局)
         * @prop {String} _label_width 标签宽度 {单位:px,默认:80px}
         * @prop {String} _label_align label对齐方式 可选: center,left,right {默认:left}
         * @prop {String} _width 标签宽度 {单位:px}
         * @prop {String} _height 标签高度 {单位:px}
         * @prop {String} _fdata 表单数据初始化值
         * @prop {String} _edit_flag {默认:'true'} 表单是否可编辑
         * @prop {String} _readonly 表单输入项为只读，不可通过键盘输入，选择类型的输入项可通过选择设置输入项内容，比如checkbox，radio，drop，日期等
         * @prop {String} _disabled 表单不可编辑，不可输入，不可选择，不可提交
         */
    props: {
        '_action': String,
        '_server': String,
        '_method': {
            type: String,
            default: 'post'
        },
        '_cols': {
            type: String,
            default: '12'
        },
        '_label_width': String,
        '_label_align': {
            type: String,
            default: 'left'
        },
        '_enctype': {
            type: String,
            default: 'application/x-www-form-urlencoded'
        },
        '_readonly': {
            type: String,
            default: 'false'
        },
        '_disabled': {
            type: String,
            default: 'false'
        },
        '_fdata': String,
        '_view': String,
        '_valid_callback': String,
        '_render_form_code': String,
        '_render_form_id': String,
        '_render_form_data_alias': {
            type: String,
            default: 'formInfo'
        },
        '_render_form_url': String,
        '_render_form_value_url': String,
        '_biz_id': String
    },
    computed: {
        hasError() {
            let errors = this.validData.errors;
            let flag = false;
            for (let key in errors) {
                if (errors[key]['items'].length > 0) {
                    flag = true;
                }
            }
            return flag;
        },
        getReadonly() {
            return Common.toBool(this.readonly)
        },
        getDisabled() {
            return Common.toBool(this.disabled)
        },
        needRenderForm() {
            return !!this.render_form_id || !!this.render_form_code;
        }
    },
    methods: {
        renderForm() {
            const _this = this;
            _this.action = _this.action || '/form/formApiController/saveFormValue';
            _this.render_form_url = _this.render_form_url || '/form/formApiController/formInfo/'
            _this.render_form_value_url = _this.render_form_value_url || '/form/formApiController/formValue/'
            Common.post(_this.server + _this.render_form_url, {
                formId: _this.render_form_id,
                formCode: _this.render_form_code
            }, function (result) {
                const formInfo = result.data[_this.render_form_data_alias];
                _this.formInfo = formInfo.form;
                _this.formAttrs = formInfo.attrs;
                _this.id = _this.render_form_id;
                _this.$nextTick(() => {
                    _this.loadConfigSource();
                    Common.post(_this.server + _this.render_form_value_url, {
                        bizId: _this.biz_id
                    }, function (result) {
                        if (result.flag) {
                            _this.setFdata(result.data.fdata);
                        }
                    });
                })
            });
        },
        getInputType(type) {
            let comName = '';
            switch (type) {
                case 'TEXT':
                    comName = 'jgp-text';
                    break;
                case 'AREA':
                    comName = 'jgp-area';
                    break;
                case 'DATE_TIME':
                case 'DATE':
                case 'TIME':
                case 'YEAR':
                case 'MONTH':
                    comName = 'jgp-date-time';
                    break;
                case 'NUMBER':
                    comName = 'jgp-num';
                    break;
                case 'DROP_TREE':
                    comName = 'jgp-drop';
                    break;
                case 'LIST_SINGLE':
                    comName = 'jgp-radio';
                    break;
                case 'LIST_MULTI':
                    comName = 'jgp-checkbox';
                    break;
                case 'DROP_SINGLE':
                    comName = 'jgp-drop';
                    break;
                case 'DROP_MULTI':
                    comName = 'jgp-drop';
                    break;
            }
            return comName;
        },
        getFormat(type) {
            let format;
            switch (type) {
                case 'DATE_TIME':
                    format = 'datetime';
                    break;
                case 'DATE':
                    format = 'date';
                    break;
                case 'TIME':
                    format = 'time';
                    break;
                case 'YEAR':
                    format = 'year';
                    break;
                case 'MONTH':
                    format = 'month';
                    break;
                case 'DROP_SINGLE':
                    format = false;
                    break;
                case 'DROP_MULTI':
                    format = true;
                    break;
            }
            return format;
        },
        /**
             * @name validForm
             * @function
             * @desc 用于校验
             * @return {boolean}
             */
        validForm(callback) {
            const _this = this;
            let arr = [];
            for (let $el of _this.els) {
                if ($el.el.cType !== 'jgp-hidden' && $el.el.cType !== 'jgp-tags') {
                    if ($el.el.cType === 'jgp-drop') {
                        arr.push($el.el.$refs.text.valid());
                    } else {
                        arr.push($el.el.valid());
                    }
                }
            }

            Promise.all(arr).then((data) => {
                if (!_this.hasError || data.indexOf(false) === -1) {
                    if (callback) {
                        callback();
                    } else if (!_this.needRenderForm) {
                        _this.$refs.form.submit();
                    }
                    return;
                }
                Common.error('请处理表单错误', 2);
                if (this.valid_callback) {
                    Common.doFn(this.valid_callback)
                }
            });
        },
        /**
             * @name submit
             * @function
             * @desc 表单提交数据
             */
        submit() {
            if (this.getDisabled) {
                Common.warn('表单不可编辑');
                return;
            }
            this.$nextTick(() => {
                this.validForm();
            })
        },
        /**
             * @name submitTo
             * @function
             * @param action {String} 提交地址
             * @desc 表单提交数据
             */
        submitTo(action) {
            this.$set(this, 'action', action);
            this.submit()
        },
        /**
             * @name ajaxSubmit
             * @function
             * @param callback {Function} 成功回调
             * @param errorCallback {Function} 失败回调 如果errorCallback为空则失败回调也会掉callback
             * @desc ajax提交表单
             */
        ajaxSubmit(callback, errorCallback) {
            const _this = this;
            if (_this.getDisabled) {
                Common.warn('表单不可编辑');
                return;
            }
            _this.$nextTick(() => {
                _this.validForm(() => {
                    if (!errorCallback) errorCallback = callback;
                    if (_this.needRenderForm) {
                        if (!_this.biz_id) {
                            Common.warn('业务ID _biz_id 不能为空');
                        } else {
                            const data = Common.getFormData(_this.$refs.form);
                            Common.postJson(_this.server + _this.action, {
                                formId: _this.render_form_id,
                                formCode: _this.render_form_code,
                                bizId: _this.biz_id,
                                data: data
                            }, callback, errorCallback);
                        }
                    } else {
                        Common.httpForm(_this.$refs.form, callback, errorCallback);
                    }
                })
            })
        },
        /**
             * @name ajaxSubmitTo
             * @function
             * @param action {String} 提交地址
             * @param callback {Function} 成功回调
             * @param errorCallback {Function} 失败回调 如果errorCallback为空则失败回调也会掉callback
             * @desc ajax提交表单
             */
        ajaxSubmitTo(action, callback, errorCallback) {
            this.$set(this, 'action', action);
            this.ajaxSubmit(callback, errorCallback)
        },
        changeErrors(name, errors) {
            this.$set(this.validData, "errors", Object.assign({}, this.validData.errors, {
                [name]: errors
            }))
        },
        setKeyValue(name, value) {
            this.$set(this.keyValue, name, value);
        },
        /**
             * @name field
             * @function
             * @param name 表单项name
             * @param value  表单项设置
             * @param submit {Bool} 是否参与提交
             * @desc 用于设置表单项的值，如果表单中没有此name，则新建hidden
             */
        field(name, value, submit = true) {
            let flag = false;
            let val;
            for (let $el of this.els) {
                if ($el.el.name === name) {
                    if (!Common.checkInputValue(value)) {
                        val = $el.el.val();
                    } else {
                        $el.el.val(value, submit);
                    }
                    flag = true;
                }
            }
            if (!flag && submit) {
                for (let h of this.hiddens) {
                    if (h.name === name) {
                        if (!Common.checkInputValue(value)) {
                            val = h.value;
                        } else {
                            h.value = value;
                        }
                        flag = true;
                    }
                }
            }

            if (!flag && Common.checkInputValue(value)) {
                this.hiddens.push({ name: name, value: value, submit: submit })
            } else {
                return val;
            }
        },
        load() {
            let formData = Common.toJson(this.fdata);
            if (!formData) return;
            var fields = formData.fields;
            try {
                for (var f = 0; f < fields.length; f++) {
                    let field = fields[f];
                    if (Common.checkInputValue(field.value)) {
                        let val = field.value;
                        let name = field.name;
                        if (field.prefix && field.prefix !== '') {
                            name = `${field.prefix}.${name}`;
                        }

                        if (Check.object(val)) {
                            for (let n in val) {
                                const childValue = val[n];
                                const childName = name + '.' + n
                                if (Common.checkInputValue(childValue)) {
                                    this.field(childName, childValue, false)
                                }
                            }
                        } else {
                            this.field(name, val, true)
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },
        /**
             * @name setFdata
             * @function
             * @param data {Object|String}
             * @desc 设置表单数据
             * @deprecated
             */
        setFdata(data) {
            if (Check.object(data)) {
                this.fdata = JSON.stringify(data);
            } else {
                this.fdata = data;
            }
            this.load();
        },
        /**
             * @name setData
             * @function
             * @param data {Object|String}
             * @desc 设置表单数据
             */
        setData(data) {
            for (let key in data) {
                this.field(key, data[key]);
            }
        },
        /**
             * @name getData
             * @function
             * @param callback(data) data 为表单数据
             * @desc 获取表单数据 此方法特殊性，必须在dom渲染完成才能取值，内部为异步处理所以必须用回调取值
             */
        getData(callback) {
            this.$nextTick(() => {
                if (callback) {
                    callback(Common.getFormData(this.$refs.form));
                }
            })
        },
        /**
             * @name reset
             * @function
             * @desc 清空已输入的内容
             */
        reset() {
            for (let $el of this.els) {
                $el.el.reset();
            }
        },
        removeEl(name) {
            const index = this.els.findIndex(el => el.name === name);
            this.els.splice(index, 1);
        },
        loadConfigSource() {
            const drops = ['jgp-drop', 'jgp-checkbox', 'jgp-radio']
            let inputs = this.els.filter(el => {
                return drops.indexOf(el.el.cType) !== -1
            }).map(el => el.el);
            let postData = {};

            for (let i = 0; i < inputs.length; i++) {
                let input = inputs[i];
                let active_key = inputs[i].active_key;
                if (active_key) {
                    postData[input.name] = {
                        active_key,
                        input
                    };
                }
            }

            let values = Object.values(postData);
            let activeKeys = [];
            for (let i = 0; i < values.length; i++) {
                let value = values[i];
                activeKeys.push(value.active_key);
            }

            if (activeKeys.length > 0) {
                Common.postJson('/sys/config-drop-all', activeKeys, (result) => {
                    if (result.flag) {
                        let dropMap = result.data.dropMap;
                        for (let name in postData) {
                            let obj = postData[name];
                            let list = dropMap[obj.active_key];
                            obj.input.attr("_list", list);
                        }
                    }
                })
            }
        }
    },
    /*
         在实例初始化之后，数据观测 (data observer)
         和 event/watcher 事件配置之前被调用。
         */
    beforeCreate() {
    },
    /*
         在实例创建完成后被立即调用。在这一步，实例已完成以下
         的配置：数据观测 (data observer)，属性和方法的运算，
         watch/event 事件回调。然而，挂载阶段还没开始，
         $ el 属性目前不可见。
         */
    created() {

    },
    /*
         在挂载开始之前被调用：相关的 render 函数首次被调用。
         */
    beforeMount() {
    },
    /*
         el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
         如果 root 实例挂载了一个文档内元素，当 mounted 被调用时
         vm.$ el 也在文档内。

         注意 mounted 不会承诺所有的子组件也都一起被挂载。如果你希望
         等到整个视图都渲染完毕，可以用 vm.$ nextTick 替换掉 mounted：
         */
    mounted() {
        this.$nextTick(() => {
            if (this.render_form_id || this.render_form_code) {
                this.renderForm();
            } else {
                this.loadConfigSource();
                this.load()
            }
        })
    },
    /*
         数据更新时调用，发生在虚拟 DOM 打补丁之前。这里适合在更新之前访问现有的 DOM，
         比如手动移除已添加的事件监听器。
         */
    beforeUpdate() {
    },
    /*
         由于数据更改导致的虚拟 DOM 重新渲染和打补丁，在这之后会调用该钩子。

         当这个钩子被调用时，组件 DOM 已经更新，所以你现在可以执行依赖于 DOM 的操作。
         然而在大多数情况下，你应该避免在此期间更改状态。如果要相应状态改变，通常最好使
         用计算属性或 watcher 取而代之。

         注意 updated 不会承诺所有的子组件也都一起被重绘。如果你希望等到整个视图都重
         绘完毕，可以用 vm.$ nextTick 替换掉 updated：
         */
    updated() {
    },
    /* keep-alive 组件激活时调用。 */
    activated() {
    },
    /* keep-alive 组件停用时调用。 */
    deactivated() {
    },
    /* 实例销毁之前调用。在这一步，实例仍然完全可用。 */
    beforeDestroy() {
    },
    /* Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，所有的事件监听器会被移除，所有的子实例也会被销毁。 */
    destroyed() {
    }
}
</script>
